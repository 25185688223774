import { useMutation } from '@tanstack/react-query';
import { post } from 'utils/api';

const url = process.env.REACT_APP_BASE_URL;

export function useAddNewUser(payload: { userName: string; password: string; firstName: string; lastName: string }) {
  return useMutation({
    mutationFn: () => addNewUser(payload),
  });
}

async function addNewUser(payload: { userName: string; password: string; firstName: string; lastName: string }) {
  const data = await post({ path: `${url}/Admin/User/Register`, payload: payload });
  return data;
}
