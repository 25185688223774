import { useMutation } from '@tanstack/react-query';
import { post } from 'utils/api';

const url = process.env.REACT_APP_BASE_URL;

export function UseClear() {
  return useMutation({
    mutationFn: clear,
  });
}

async function clear() {
  const data = await post({ path: `${url}/Assistant/CleanThread`, payload: {} });
  return data;
}
