import { useMutation, useQuery } from '@tanstack/react-query';
import { get, post } from 'utils/api';

const url = process.env.REACT_APP_BASE_URL;

const USERS = 'users';

export function useUsers() {
  return useQuery({
    queryKey: [USERS],
    queryFn: () => getUsers(),
  });
}

async function getUsers() {
  const { users } = await get({ path: `${url}/Admin/User/GetList` });
  return users;
}
