import { Form, Formik } from 'formik';
import { useContext, useEffect, useState } from 'react';
import * as Yup from 'yup';
import { ArrowIcon } from '../../assets';
import { Button, Input, Loader } from '../../components';
import { MainContext } from '../../context/MainContext';
import { UseLogin } from './data/UseLogin';

const validationSchema = Yup.object().shape({
  userName: Yup.string().required('Required'),
  password: Yup.string()
    .min(6, 'Your password must be at least 6 characters.')
    .max(50, 'Too Long!')
    .required('Required'),
});

const initialValues = {
  userName: '',
  password: '',
};

type LoginP = {
  setIsOpen: (arg: boolean) => any;
};
export default function Login({ setIsOpen }: LoginP) {
  const [isValues, setValues] = useState(initialValues);
  const { data, isPending, mutate, isSuccess, error } = UseLogin(isValues);
  const { loginF } = useContext(MainContext);

  useEffect(() => {
    isSuccess && setIsOpen(false);
  }, [isSuccess]);

  useEffect(() => {
    data && loginF(data?.token);
  }, [data]);

  return (
    <>
      <h2 className="text-[30px] sm:text-[64px] font-[500] text-white uppercase tracking-[-1.92px] leading-1 text-center mt-[20px] mb-[20px]  sm:mt-[44px]  sm:mb-[42px]">
        sign in
      </h2>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={async (values) => {
          setValues(values);
          mutate();
        }}
      >
        {() => (
          <Form>
            <Input label="login" name="userName" placeholder="Enter Your Login" errorRes={error} />
            <Input label="password" type="password" name="password" placeholder="***********" />
            <Button type="submit" customStyle="w-full px-[15px] py-[16px] mt-[26px] group relative" style="white">
              <span className="absolute transition duration-300 transition-all left-[15px] top-[50%] translate-y-[-50%] group-hover:left-[calc(50%-60px)]">
                <ArrowIcon opacity />
              </span>
              Login
              <span className="absolute transition duration-300 transition-all right-[15px] top-[50%] translate-y-[-50%] group-hover:right-[calc(50%-60px)]">
                <ArrowIcon opacity rotate />
              </span>
            </Button>
          </Form>
        )}
      </Formik>
      <Loader isLoading={isPending} />
    </>
  );
}
