import { ThreeDots } from 'react-loader-spinner';
import './Button.css';
import { NavLink } from 'react-router-dom';

type ButtonP = {
  children: React.ReactNode;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  type?: 'submit' | 'reset' | 'button';
  customStyle?: string;
  disabled?: boolean;
  style?: 'white' | 'black';
  loading?: boolean;
  component?: 'button' | 'a' | 'span' | 'div' | 'NavLink';
  to?: string;
};

export default function Button({
  children,
  onClick,
  type = 'button',
  customStyle,
  component = 'button',
  disabled = false,
  style = 'black',
  to,
  loading,
}: ButtonP) {
  const styleForButton = () => {
    switch (style) {
      case 'black':
        return 'black text-white bg-transparent border-white hover:bg-white hover:text-black';
      case 'white':
        return 'white text-black bg-white border-white hover:text-white hover:bg-transparent ';

      default:
        break;
    }
  };
  const Component: any = component === 'NavLink' ? NavLink : component;

  return (
    <Component
      type={type}
      onClick={onClick}
      to={to ?? ''}
      disabled={disabled || loading}
      style={{ backdropFilter: 'blur(6px)' }}
      className={`button relative flex leading-[24px] [&.active]:hidden justify-center group gap-[10px] transition duration-300 transition-all items-center border px-[10px]  py-[8px] sm:px-[12px] font-[500] text-[12px] sm:py-[12px] sm:px-[17px] sm:font-[500] sm:text-[14px] uppercase ${styleForButton()} ${customStyle}`}
    >
      {loading && (
        <ThreeDots
          wrapperClass="absolute w-full top-0 left-0 right-0 bottom-0 flex items-center justify-center bg-[rgba(0,0,0,0.8)]"
          height="20"
          color="#7c4296"
          width="20"
        />
      )}
      {children}
    </Component>
  );
}
