import { useMutation } from '@tanstack/react-query';
import { post } from 'utils/api';

const url = process.env.REACT_APP_BASE_URL;

export function UseLogin(payload: { userName: string; password: string }) {
  return useMutation({
    mutationFn: () => login(payload),
  });
}

async function login(payload: { userName: string; password: string }) {
  const data = await post({ path: `${url}/User/LogIn`, payload: payload });
  return data;
}
