import axios from 'axios';

const headers = () => {
  const token = localStorage.getItem('mainToken');
  const authMainConfig = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return token ? authMainConfig : {};
};

export async function post({ path, payload }: { path: string; payload: any }) {
  const { data } = await axios.post(path, payload, headers());
  return data || null;
}

export async function get({ path }: { path: string }) {
  const { data } = await axios.get(path, headers());
  return data;
}
