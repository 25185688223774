import { QueryObserverResult, RefetchOptions } from '@tanstack/react-query';
import { ArrowIcon } from 'assets';
import { Button, Loader, Select } from 'components';
import { Form, Formik } from 'formik';
import { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { useSetAssistantToUser } from 'containers/AdminPanel/data/useSetAssistantToUser';
import { useAssistants } from 'containers/AdminPanel/data/useAssistants';
import { useUsers } from 'containers/AdminPanel/data/useUsers';

const validationSchema = Yup.object().shape({
  userId: Yup.string().required('Required'),
  assistantId: Yup.string().required('Required'),
});

const initialValues = {
  userId: '',
  assistantId: '',
};

type LoginP = {
  setIsOpen: (arg: boolean) => any;
};
export default function SetAssistantToUser({ setIsOpen }: LoginP) {
  const [isValues, setValues] = useState(initialValues);
  const { isPending, mutate, isSuccess } = useSetAssistantToUser(isValues);
  const { data: dataAssistants, isLoading: isLoadingAssistants, refetch: refetchAssistants } = useAssistants();
  const { data: dataUsers, isLoading: isLoadingUsers, refetch: refetchUsers } = useUsers();

  const optionsAssistants =
    dataAssistants?.map(({ id, name }: { id: string; name: string }) => ({
      value: id,
      name: name,
    })) ?? [];

  const optionsUsers =
    dataUsers?.map(({ firstName, id, lastName }: { id: string; firstName: string; lastName: string }) => ({
      value: id,
      name: `${firstName} ${lastName} ID: ${id}`,
    })) ?? [];

  useEffect(() => {
    if (isSuccess) {
      setIsOpen(false);
      refetchAssistants();
      refetchUsers();
    }
  }, [isSuccess]);

  return (
    <>
      <h2 className="text-[30px] sm:text-[64px] font-[500] text-white uppercase tracking-[-1.92px] leading-1 text-center mt-[20px] mb-[20px]  sm:mt-[44px]  sm:mb-[42px]">
        Set Assistant To User
      </h2>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={async (values) => {
          setValues(values);
          mutate();
        }}
      >
        {() => (
          <Form>
            <Select options={optionsUsers} name="userId" label="User Id" placeholder="Select User Id" />
            <Select
              options={optionsAssistants}
              name="assistantId"
              label="Assistant Id"
              placeholder="Select Assistant Id"
            />

            <Button type="submit" customStyle="w-full px-[15px] py-[16px] mt-[26px] group relative" style="white">
              <span className="absolute transition duration-300 transition-all left-[15px] top-[50%] translate-y-[-50%] group-hover:left-[calc(50%-60px)]">
                <ArrowIcon opacity />
              </span>
              Set
              <span className="absolute transition duration-300 transition-all right-[15px] top-[50%] translate-y-[-50%] group-hover:right-[calc(50%-60px)]">
                <ArrowIcon opacity rotate />
              </span>
            </Button>
          </Form>
        )}
      </Formik>
      <Loader isLoading={isPending || isLoadingAssistants || isLoadingUsers} />
    </>
  );
}
