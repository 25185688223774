import { MainContext } from 'context/MainContext';
import { jwtDecode } from 'jwt-decode';
import { useContext, useEffect, useState } from 'react';

interface User {
  role: 'Admin';
  Id: string;
}

const useGetUser = (): User | null => {
  const { login } = useContext(MainContext);

  const [mainToken, setMainToken] = useState<User | null>(() => {
    const token = localStorage.getItem('mainToken');
    return token ? jwtDecode(token) : null;
  });

  useEffect(() => {
    const token = localStorage.getItem('mainToken');
    if (token) {
      setMainToken(jwtDecode(token));
    } else {
      setMainToken(null);
    }
  }, [login]);

  return mainToken;
};

export default useGetUser;
