import { Loader, Table, Button, Modal } from 'components';
import { useState } from 'react';
import { useAssistants } from '../data/useAssistants';
import { SetAssistantToUser } from './componets';
export default function AssistantsTab() {
  const { data, isLoading } = useAssistants();
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div>
      <Button onClick={() => setIsOpen(!isOpen)} customStyle="ml-auto mb-[20px]">
        Set Assistant To User
      </Button>
      <Loader isLoading={isLoading} />
      <Table data={data} />
      <Modal isOpen={isOpen} setIsOpen={setIsOpen}>
        <SetAssistantToUser setIsOpen={setIsOpen} />
      </Modal>
    </div>
  );
}
