import { AdminPanel, Dashboard } from '../containers';
import { Navigate, Route, Routes } from 'react-router-dom';
import useGetUser from 'utils/useGetUser';

function RoutesApp() {
  const user = useGetUser();
  const isAdmin = user?.role === 'Admin';

  return (
    <Routes>
      <Route path="/" element={<Dashboard />} />
      <Route path="*" element={<Dashboard />} />
      <Route path="admin-panel" element={isAdmin ? <AdminPanel /> : <Navigate to="/" replace />} />
      <Route path="chat-public/:assistantId" element={<Dashboard publicT />}>
        <Route path=":threadID" element={<Dashboard publicT />} />
      </Route>
      <Route path="chat-public/modal/:assistantId" element={<Dashboard publicT publicChat />}>
        <Route path=":typeDevice" element={<Dashboard publicT  />} />
      </Route>
    </Routes>
  );
}

export default RoutesApp;
