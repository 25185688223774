import { createContext, Dispatch, SetStateAction } from 'react';

interface MainContextT {
  login: boolean;
  setLogin: Dispatch<SetStateAction<boolean>>;
  isOpenLogin: boolean;
  setIsOpenLogin: Dispatch<SetStateAction<boolean>>;
  isStart: boolean;
  setIsStart: Dispatch<SetStateAction<boolean>>;
  logout: () => void;
  loginF: (token: string) => void;
  isChat: boolean;
  setIsChat: Dispatch<SetStateAction<boolean>>;

  isPublicMode: boolean;
  setIsPublicMode: Dispatch<SetStateAction<boolean>>;

  isPublicChat: boolean;
  setIsPublicChat: Dispatch<SetStateAction<boolean>>;

  threadId: string;
  setThreadId: Dispatch<SetStateAction<string>>;
}

export const MainContext = createContext<MainContextT>({
  login: false,
  setLogin: () => {},
  isOpenLogin: false,
  setIsOpenLogin: () => {},
  isStart: false,
  setIsStart: () => {},
  setIsChat: () => {},
  isChat: false,
  logout: () => {},
  loginF: () => {},
  isPublicMode: false,
  setIsPublicMode: () => {},
  threadId: '',
  setThreadId: () => {},
  isPublicChat: false,
  setIsPublicChat: () => {},
});
