import { TailSpin } from 'react-loader-spinner';

type LoaderP = {
  isLoading: boolean;
};

export default function Loader({ isLoading }: LoaderP) {
  return isLoading ? (
    <div className="absolute bg-[rgba(30,30,30,0.72)] top-0 left-0 right-0 bottom-0 z-10 flex items-center justify-center">
      <TailSpin
        visible={true}
        height="80"
        width="80"
        radius="0"
        color="#7c4296"
      />
    </div>
  ) : null;
}
