export default function CloseIcon() {
  return (
    <svg width="38" height="38" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="Close">
        <line id="Line 1066" x1="8.36448" y1="7.24392" x2="30.7562" y2="29.6356" stroke="white" strokeWidth="1.58333" />
        <line
          id="Line 1068"
          y1="-0.791667"
          x2="31.6667"
          y2="-0.791667"
          transform="matrix(-0.707107 0.707107 0.707107 0.707107 30.1973 7.80371)"
          stroke="white"
          strokeWidth="1.58333"
        />
      </g>
    </svg>
  );
}
