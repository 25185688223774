type ArrowIconP = {
  rotate?: boolean;
  opacity?: boolean;
};
export default function ArrowIcon({ rotate, opacity }: ArrowIconP) {
  return (
    <svg
      className={`${rotate ? 'rotate-180' : ''} ${opacity ? 'opacity-[0.8]' : ''}`}
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="15"
      viewBox="0 0 12 15"
      fill="none"
    >
      <path id="Polygon 4" d="M12 7.5L-1.78017e-07 0.571797L-1.27811e-08 14.4282L12 7.5Z" fill="#0E0E0E" />
    </svg>
  );
}
