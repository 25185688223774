import { MainContext } from 'context/MainContext';
import { useContext } from 'react';

type MessageItemP = {
  to: boolean;
  text: string;
  load?: boolean;
  index: number;
};

export default function MessageItem({ to, text, load, index }: MessageItemP) {
  const { setIsChat, isPublicChat } = useContext(MainContext);

  function replaceTextWithLinks(text: string): string {
    const regex = /(\[([^\]]+)\]\(([^)]+)\))|\n\n(\d+)/g;
    return text.replace(regex, (match, link, title, url, number) => {
      if (link) {
        return `<a class="text-[12px] sm:text-[14px] underline" target="_blank" href="${url}">${title ?? url}</a>`;
      } else if (number) {
        return `\n\n${number}`;
      }
      return match;
    });
  }

  return (
    <div
      className={`border border-white p-[11px] sm:p-[20px] flex gap-[10px] sm:gap-[20px] text-white   w-full ${isPublicChat && 'relative  max-w-[calc(100%-58px)]'}  ${isPublicChat && index !== 0 && ''} ${
        to ? (isPublicChat ? 'bg-nero  ml-auto mr-[14px]' : 'bg-iron') : !isPublicChat ? 'bg-nero' : 'mr-auto ml-[14px]'
      }`}
    >
      {isPublicChat && !to && (
        <svg
          className="absolute scale-y-[1] scale-x-[-1] absolute left-[-16px] top-[-1.5px]"
          xmlns="http://www.w3.org/2000/svg"
          width="35"
          height="16"
          viewBox="0 0 35 16"
          fill="none"
        >
          <path d="M34 1H20V15L34 1Z" fill="#0E0E0E" />
          <path d="M16.0345 1H30H15" stroke="white" />
          <path d="M0 1.5H20V14.5H0V1.5Z" fill="#0E0E0E" />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M20.0001 1.5H32.293L19.293 14.5H19.2999V15.7998L19.3536 15.8536L33.8536 1.35355L34.7072 0.5H33.5001H20.0001V1.5Z"
            fill="white"
          />
        </svg>
      )}

      {isPublicChat && to && (
        <svg
          className="absolute  absolute right-[-16px] top-[-1.5px]"
          width="35"
          height="16"
          viewBox="0 0 35 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M34 1H20V15L34 1Z" fill="#262626" />
          <rect y="1.5" width="20" height="13" fill="#262626" />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M20.0001 1.5H32.293L19.293 14.5H19.2999V15.7998L19.3536 15.8536L33.8536 1.35355L34.7072 0.5H33.5001H20.0001V1.5Z"
            fill="white"
          />
          <path d="M16 1H29.5L15 1.0001" stroke="white" />
        </svg>
      )}
      <div className="flex flex-col gap-[8px] w-full">
        <div
          className={`sm:text-[20px] tracking-[-0.20px] uppercase ${isPublicChat && '!text-[12px]'} ${isPublicChat && to && 'hidden'}`}
        >
          {to ? 'user' : 'Improvs Assistant'}
        </div>
        <div
          className={`text-[12px] text-[#DBDBDB] sm:text-[14px] sm:max-w-[60%]  ${isPublicChat && '!text-[14px]'}`}
          dangerouslySetInnerHTML={{ __html: replaceTextWithLinks(text)?.replace(/\n/g, '<br>') }}
        />
      </div>
    </div>
  );
}
