import { useContext, useEffect } from 'react';
import { Chat, Container, Modal } from '../../components';
import { MainContext } from '../../context/MainContext';
import { Login } from '../Login';

type DashboardP = {
  publicT?: any;
  publicChat?: boolean;
};

export default function Dashboard({ publicT = false, publicChat = false }: DashboardP) {
  const { setIsOpenLogin, isOpenLogin, setIsPublicMode, isPublicMode, setIsPublicChat, isPublicChat } =
    useContext(MainContext);

  useEffect(() => {
    setIsPublicMode(publicT);
  }, [publicT]);

  useEffect(() => {
    setIsPublicChat(publicChat);
  }, [publicChat]);

  return (
    <>
      <main className={`${!isPublicChat && 'pb-[30px] sm:pb-[20px]'} `}>
        <Container>
          <Chat />
        </Container>
      </main>

      {!isPublicMode && (
        <Modal isOpen={isOpenLogin} setIsOpen={setIsOpenLogin}>
          <Login setIsOpen={setIsOpenLogin} />
        </Modal>
      )}
    </>
  );
}
