import { CopyIcon } from 'assets';
import React, { useState } from 'react';

export type TableRow = {
  [key: string]: string | number | null | undefined | React.ReactNode;
  id: number;
  isDisable: any;
};

type TableProps = {
  data?: TableRow[];
};

const Table: React.FC<TableProps> = ({ data }) => {
  const [expandedRows, setExpandedRows] = useState<number[]>([]);

  const toggleRowExpansion = (rowIndex: number) => {
    if (expandedRows.includes(rowIndex)) {
      setExpandedRows(expandedRows.filter((row) => row !== rowIndex));
    } else {
      setExpandedRows([...expandedRows, rowIndex]);
    }
  };

  const copyValueToClipboard = (value: string) => {
    navigator.clipboard.writeText(value);
  };

  const renderTableCell = (value: string | number | null | undefined, index: number, rowIndex: number) => {
    const isExpanded = expandedRows.includes(rowIndex);
    const truncatedValue = typeof value === 'string' && value.length > 100 ? value.substring(0, 100) + '...' : value;

    return (
      <td key={index} className="border max-w-[450px] relative p-2">
        <div className="flex items-center gap-[4px] justify-between">
          <div>
            {isExpanded ? value : truncatedValue}
            {typeof value === 'string' && value.length > 100 && (
              <button onClick={() => toggleRowExpansion(rowIndex)} className="text-blue-500 ml-2">
                {isExpanded ? 'Hide' : 'More'}
              </button>
            )}
          </div>
          {typeof value === 'string' && (
            <button className="group  cursor-copy" onClick={() => copyValueToClipboard(String(value))}>
              <CopyIcon />
            </button>
          )}
        </div>
      </td>
    );
  };

  const renderTableBody = () => {
    if (!data?.length) return null;
    return data.map((row, rowIndex) => (
      <tr key={rowIndex} className="border">
        {Object.values(row).map((value, index) => renderTableCell(value, index, rowIndex))}
      </tr>
    ));
  };

  const renderTableHeader = () => {
    if (!data) return null;

    return (
      <tr>
        {Object.keys(data[0]).map((key, index) => (
          <th className="p-2  capitalize border border-white" key={index}>
            {key}
          </th>
        ))}
      </tr>
    );
  };

  return (
    <div className="overflow-x-auto">
      <table className="w-full table-auto">
        <thead>{renderTableHeader()}</thead>
        <tbody>{renderTableBody()}</tbody>
      </table>
    </div>
  );
};

export default Table;
