import { useMutation, useQuery } from '@tanstack/react-query';
import { get, post } from 'utils/api';

const url = process.env.REACT_APP_BASE_URL;

const KEY = 'assistants';

export function useAssistants() {
  return useQuery({
    queryKey: [KEY],
    queryFn: () => getAssistants(),
  });
}

async function getAssistants() {
  const { assistants } = await get({ path: `${url}/Admin/Assistant/GetList` });
  return assistants;
}
