import { Loader, Table, Button, Modal } from 'components';
import { TableRow } from 'components/Table/Table';
import { useState } from 'react';
import { useUsers } from '../data/useUsers';
import { SwitcherEnableUser } from './componets';
import AddNewUser from './componets/AddNewUser';

export default function UsersTab() {
  const { data, isLoading, refetch } = useUsers();
  const [isOpen, setIsOpen] = useState(false);

  const users = (data: TableRow[]) => {
    if (!data) return;
    const newArray = data?.map((item) => ({
      ...item,
      isDisable: <SwitcherEnableUser id={item.id} refetch={refetch} value={item.isDisable} />,
    }));
    return newArray.sort((a, b) => a.id - b.id);
  };

  return (
    <div>
      <Button onClick={() => setIsOpen(!isOpen)} customStyle="ml-auto mb-[20px]">
        Add new User
      </Button>
      <Loader isLoading={isLoading} />
      <Table data={users(data)} />
      <Modal isOpen={isOpen} setIsOpen={setIsOpen}>
        <AddNewUser setIsOpen={setIsOpen} refetch={refetch} />
      </Modal>
    </div>
  );
}
