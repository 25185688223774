import { UseClear } from 'components/Chat/data/UseClear';
import { useContext, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { Link } from 'react-router-dom';
import useGetUser from 'utils/useGetUser';
import { ClearIcon, LogOutIcon } from '../../../assets';
import logo from '../../../assets/images/logo.png';
import { MainContext } from '../../../context/MainContext';
import { Button } from '../../Button';

export default function Header() {
  const { login, setIsOpenLogin, logout, setIsStart, isChat, isPublicMode, setThreadId, isPublicChat } =
    useContext(MainContext);
  const { mutate: clear, isSuccess, isPending } = UseClear();
  const user = useGetUser();
  const isAdmin = user?.role === 'Admin';

  const clearFn = () => {
    isPublicMode ? setThreadId('') : clear();
  };

  const handleActionLogin = () => {
    login ? logout() : setIsOpenLogin(true);
  };

  useEffect(() => {
    isSuccess && setIsStart(false);
  }, [isSuccess]);

  return (
    <>
      {!isPublicChat && (
        <header className="flex items-center justify-between py-[20px]">
          <a href="https://improvs.com/" className="max-w-[80px] sm:max-w-none" target="_blanck">
            <img className="max-w-[124px]" src={logo} alt="logo" />
          </a>
          <div className="flex items-center gap-[20px]">
            {isAdmin && (
              <>
                <Button component="NavLink" to="/">
                  Chat
                </Button>
                <Button component="NavLink" to="/admin-panel">
                  Admin Panel
                </Button>
              </>
            )}
            {(isPublicMode || login) && isChat && (
              <Button loading={isPending} onClick={clearFn}>
                clear <ClearIcon />
              </Button>
            )}
            {!isPublicMode && (
              <Button onClick={handleActionLogin}>
                {login ? 'lOG OUT' : 'login'} <LogOutIcon />
              </Button>
            )}
          </div>
        </header>
      )}
    </>
  );
}
