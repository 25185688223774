import { useMutation } from '@tanstack/react-query';
import { post } from 'utils/api';

const url = process.env.REACT_APP_BASE_URL;

export function useSetAssistantToUser(payload: { userId: string; assistantId: string }) {
  return useMutation({
    mutationFn: () => setAssistantToUser(payload),
  });
}

async function setAssistantToUser({ userId, assistantId }: { userId: string; assistantId: string }) {
  const data = await post({
    path: `${url}/Admin/Assistant/${assistantId}/SetAssistantToUser/${userId}`,
    payload: {},
  });
  return data;
}
