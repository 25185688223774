import { ErrorMessage, Field, Form, Formik } from 'formik';
import { ArrowIcon } from '../../assets';
import { Button } from '../Button';
import * as Yup from 'yup';
import { useContext, useEffect, useRef, useState } from 'react';
import { MainContext } from '../../context/MainContext';
import { UseMessageSend, UseRetrieveRun } from './data/UseMessage';
import { QueryObserverResult, RefetchOptions } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';

type FieldChatP = {
  updateMessages: (options?: RefetchOptions | undefined) => Promise<QueryObserverResult<any, Error>>;
  isLoadingMessages: boolean;
  setMassages: React.Dispatch<React.SetStateAction<any>>;
  massages?: any[];
};

const SignupSchema = Yup.object().shape({
  message: Yup.string().trim().required('Required'),
});

const generateRand = (arg: number): number => {
  return Math.floor(Math.random() * arg);
};

export default function FieldChat({ updateMessages, isLoadingMessages, setMassages, massages }: FieldChatP) {
  const { login, isStart, isPublicMode, threadId, isPublicChat } = useContext(MainContext);
  const { assistantId } = useParams();
  const [isMessage, setMessage] = useState('');
  const [isPendingRetrieve, setPendingRetrieve] = useState(false);
  const [isId, setId] = useState('');
  const formRef = useRef<any>();
  const { data, mutate, isPending, isSuccess } = UseMessageSend(isMessage, assistantId ?? '', threadId);
  const { data: dataRetrieve, mutate: mutateRetrieve, isSuccess: isSuccessRetrieve } = UseRetrieveRun(isId, threadId);
  const disabled = (!login && !isPublicMode) || !isStart || isPending || isLoadingMessages || isPendingRetrieve;
  const { typeDevice } = useParams();

  const handleRetrieve = () => {
    setPendingRetrieve(true);
    setId(data?.run?.id);
    setTimeout(() => {
      mutateRetrieve();
    }, 1000);
  };

  useEffect(() => {
    if (massages?.length === 0 && isPublicChat) {
      formRef.current.resetForm();
      setMessage('Hello');
      mutate();
    }
  }, [massages, isPublicChat]);

  useEffect(() => {
    if (isSuccessRetrieve) {
      if (dataRetrieve?.run?.status === 'completed') {
        updateMessages();
        setPendingRetrieve(false);
        setId('');
      } else {
        handleRetrieve();
      }
    }
  }, [isSuccessRetrieve]);

  useEffect(() => {
    if (isSuccess) {
      updateMessages();
      handleRetrieve();
    }
  }, [isSuccess]);

  useEffect(() => {
    function keydownHandler(e: any) {
      if (e.key === 'Enter' && e.ctrlKey) {
        formRef.current.handleSubmit();
        
      }
      if (e.key === 'Enter' && isPublicChat) {
        formRef.current.handleSubmit();

      }
    }
    document.addEventListener('keydown', keydownHandler);
  }, []);

  return (
    <Formik
      innerRef={formRef}
      initialValues={{
        message: '',
      }}
      validationSchema={SignupSchema}
      onSubmit={async (values) => {
        formRef.current.resetForm();
        setMessage(values.message);
        mutate();
      }}
    >
      {({ touched, errors }) => (
        <Form
          className={`mt-auto ${disabled && 'opacity-[0.5]'} ${isPublicChat && !JSON.parse(typeDevice ?? 'true') && 'px-[8px]'}`}
        >
          <label
            className={`flex justify-between items-center w-full border border-white p-[10px] sm:py-[11px] sm:px-[16px] bg-nero relative ${touched && errors.message && !isPublicChat && '!border-dark-red'}`}
          >
            <Field
              className={`w-full h-[44px] resize-none bg-transparent outline-none text-white tracking-[-0.16px]  placeholder:text-white pr-[10px] sm:pr-[20px] sm:text-[16px] text-[14px]  ${isPublicChat && '!h-[21px]  placeholder:opacity-[0.8] placeholder:text-grey-text'}`}
              placeholder="Enter your message..."
              as="textarea"
              name="message"
              disabled={disabled}
            />
            <Button
              disabled={disabled}
              type="submit"
              customStyle={`sm:text-[20px] sm:py-[18px] sm:px-[58px] tracking-[-0.20px] ${isPublicChat && 'h-[40px] w-[43px]'}`}
              style="white"
              loading={isPending || isLoadingMessages || isPendingRetrieve}
            >
              <ArrowIcon /> {!isPublicChat && 'run'}
            </Button>
            {!isPublicChat && (
              <ErrorMessage
                className="absolute text-[12px] right-0 bottom-[-16px] text-dark-red"
                component="span"
                name="message"
              />
            )}
          </label>
        </Form>
      )}
    </Formik>
  );
}
