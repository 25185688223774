import { Button } from 'components';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { AssistantsTab, UsersTab } from './tabs';

export default function AdminPanel() {
  return (
    <>
      <Tabs>
        <TabList className="flex w-full border-b border-b-white">
          <Tab selectedClassName="[&>button]:bg-white [&>button]:text-black">
            <Button>Users</Button>
          </Tab>
          <Tab selectedClassName="[&>button]:bg-white [&>button]:text-black">
            <Button>Assistants</Button>
          </Tab>
        </TabList>
        <div className="text-white py-[20px] ralative">
          <TabPanel>
            <UsersTab />
          </TabPanel>
          <TabPanel>
            <AssistantsTab />
          </TabPanel>
        </div>
      </Tabs>
    </>
  );
}
