import { ErrorMessage, Field, useField } from 'formik';
import { useEffect } from 'react';

type InputP = {
  label: string;
  name: string;
  placeholder: string;
  errorRes?: any;
  options: {
    value: string;
    name: string;
  }[];
};

export default function Select({ label, name, placeholder = '', errorRes, options }: InputP) {
  const [_, { error, touched }, { setError }] = useField(name);
  useEffect(() => {
    if (errorRes) {
      setError(errorRes?.response?.data.Message);
    }
  }, [errorRes]);

  return (
    <label className="flex flex-col gap-[6px] mb-[16px] relative">
      <span className="uppercase text-[12px] tracking-[-0.12px] text-white">{label}</span>
      <div className="relative w-full">
        <Field
          as="select"
          placeholder={placeholder}
          className={`bg-nero border w-full border-white px-[10px] py-[10px] text-[12px] sm:px-[16px]  sm:py-[16px]  sm:text-[14px] text-white outline-none ${
            touched && error && '!border-dark-red'
          }`}
          style={{ backdropFilter: 'blur(5px)' }}
          name={name}
        >
          <option>{placeholder}</option>
          {options.map(({ value, name }) => (
            <option key={value} value={value}>
              {name ?? value}
            </option>
          ))}
        </Field>
      </div>
      <ErrorMessage
        className="absolute text-[12px] right-0 bottom-[-16px] text-dark-red"
        component="span"
        name={name}
      />
    </label>
  );
}
