import { ErrorMessage, Field, useField } from 'formik';
import { useEffect, useState } from 'react';
import { EyeIcon, EyeIconOpen } from '../../../assets';

type InputP = {
  label: string;
  name: string;
  placeholder: string;
  type?: 'password' | 'text';
  errorRes?: any;
};

export default function Input({ label, name, type = 'text', placeholder = '', errorRes }: InputP) {
  const [_, { error, touched }, { setError }] = useField(name);
  const [isShowPassword, setSsShowPassword] = useState(false);
  const isPassword = type === 'password';

  useEffect(() => {
    if (errorRes) {
      setError(errorRes?.response?.data.Message);
    }
  }, [errorRes]);

  const handleChangeMode = () => {
    setSsShowPassword(!isShowPassword);
  };

  return (
    <label className="flex flex-col gap-[6px] mb-[16px] relative">
      <span className="uppercase text-[12px] tracking-[-0.12px] text-white">{label}</span>
      <div className="relative w-full">
        <Field
          placeholder={placeholder}
          className={`bg-nero border w-full border-white px-[10px] py-[10px] text-[12px] sm:px-[16px]  sm:py-[16px]  sm:text-[14px] text-white outline-none ${
            touched && error && '!border-dark-red'
          }`}
          style={{ backdropFilter: 'blur(5px)' }}
          type={isShowPassword && isPassword ? 'text' : type}
          name={name}
        />
        {isPassword && (
          <button
            type="button"
            className="absolute top-[50%] translate-y-[-50%] right-[16px]"
            onClick={handleChangeMode}
          >
            {isShowPassword ? <EyeIconOpen /> : <EyeIcon />}
          </button>
        )}
      </div>
      <ErrorMessage
        className="absolute text-[12px] right-0 bottom-[-16px] text-dark-red"
        component="span"
        name={name}
      />
    </label>
  );
}
