export default function EyeIconOpen() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="23" height="22" viewBox="0 0 22 18" fill="none">
      <path
        d="M11.0006 0C16.3926 0 20.8786 3.88 21.8196 9C20.8796 14.12 16.3926 18 11.0006 18C5.60864 18 1.12264 14.12 0.181641 9C1.12164 3.88 5.60864 0 11.0006 0ZM11.0006 16C13.0401 15.9996 15.019 15.3068 16.6135 14.0352C18.208 12.7635 19.3235 10.9883 19.7776 9C19.3219 7.0133 18.2056 5.24 16.6113 3.97003C15.017 2.70005 13.0389 2.00853 11.0006 2.00853C8.96234 2.00853 6.98433 2.70005 5.39002 3.97003C3.7957 5.24 2.67941 7.0133 2.22364 9C2.67774 10.9883 3.79331 12.7635 5.38778 14.0352C6.98225 15.3068 8.96117 15.9996 11.0006 16ZM11.0006 13.5C9.80717 13.5 8.66257 13.0259 7.81866 12.182C6.97475 11.3381 6.50064 10.1935 6.50064 9C6.50064 7.80653 6.97475 6.66193 7.81866 5.81802C8.66257 4.97411 9.80717 4.5 11.0006 4.5C12.1941 4.5 13.3387 4.97411 14.1826 5.81802C15.0265 6.66193 15.5006 7.80653 15.5006 9C15.5006 10.1935 15.0265 11.3381 14.1826 12.182C13.3387 13.0259 12.1941 13.5 11.0006 13.5ZM11.0006 11.5C11.6637 11.5 12.2996 11.2366 12.7684 10.7678C13.2372 10.2989 13.5006 9.66304 13.5006 9C13.5006 8.33696 13.2372 7.70107 12.7684 7.23223C12.2996 6.76339 11.6637 6.5 11.0006 6.5C10.3376 6.5 9.70171 6.76339 9.23287 7.23223C8.76403 7.70107 8.50064 8.33696 8.50064 9C8.50064 9.66304 8.76403 10.2989 9.23287 10.7678C9.70171 11.2366 10.3376 11.5 11.0006 11.5Z"
        fill="white"
      />
    </svg>
  );
}
