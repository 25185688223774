export default function EyeIcon() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_3178_855)">
        <path
          d="M17.8826 19.2971C16.1239 20.4126 14.0834 21.0034 12.0006 21.0001C6.60864 21.0001 2.12264 17.1201 1.18164 12.0001C1.61167 9.67078 2.78327 7.54296 4.52164 5.93407L1.39264 2.80807L2.80764 1.39307L22.6066 21.1931L21.1916 22.6071L17.8826 19.2971ZM5.93564 7.35007C4.57665 8.58566 3.62996 10.2088 3.22364 12.0001C3.53593 13.3665 4.1629 14.6412 5.05464 15.7227C5.94638 16.8041 7.07827 17.6625 8.36019 18.2294C9.64212 18.7963 11.0387 19.0561 12.4387 18.9882C13.8388 18.9203 15.2036 18.5264 16.4246 17.8381L14.3966 15.8101C13.5333 16.3539 12.5108 16.5882 11.4969 16.4745C10.4829 16.3608 9.53768 15.906 8.81621 15.1845C8.09474 14.463 7.63987 13.5178 7.5262 12.5038C7.41254 11.4899 7.64682 10.4674 8.19064 9.60407L5.93564 7.35007ZM12.9146 14.3281L9.67264 11.0861C9.49471 11.539 9.45283 12.034 9.55215 12.5104C9.65146 12.9868 9.88766 13.4238 10.2318 13.7679C10.5759 14.1121 11.0129 14.3482 11.4893 14.4476C11.9657 14.5469 12.4607 14.505 12.9136 14.3271L12.9146 14.3281ZM20.8076 16.5921L19.3766 15.1621C20.0451 14.2094 20.521 13.1353 20.7776 12.0001C20.5059 10.8098 19.9949 9.68721 19.2758 8.70056C18.5567 7.71391 17.6444 6.88379 16.5945 6.26067C15.5446 5.63755 14.3789 5.23443 13.1684 5.07583C11.9578 4.91723 10.7276 5.00645 9.55264 5.33807L7.97464 3.76007C9.22164 3.27007 10.5806 3.00007 12.0006 3.00007C17.3926 3.00007 21.8786 6.88007 22.8196 12.0001C22.5132 13.6658 21.8245 15.2376 20.8076 16.5921ZM11.7236 7.50807C12.3602 7.46873 12.9978 7.56513 13.5942 7.79088C14.1906 8.01663 14.7323 8.36658 15.1832 8.81752C15.6341 9.26846 15.9841 9.81009 16.2098 10.4065C16.4356 11.003 16.532 11.6406 16.4926 12.2771L11.7236 7.50807Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_3178_855">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
