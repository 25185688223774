export default function ClearIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path
        d="M13.75 19.5L14.4991 18.7509M14.4991 18.7509L22.25 11L14.25 3L6.49909 10.7509M14.4991 18.7509L6.75 11L6.49909 10.7509M14.4991 18.7509L12.25 21M6.49909 10.7509L1.75 15.5L7.25 21H10.25H11.75H12.25M19.75 21H12.25"
        stroke="white"
        strokeWidth="1.5"
      />
    </svg>
  );
}
