import ReactModal from 'react-modal';
import { CloseIcon } from '../../assets';
import { createPortal } from 'react-dom';
import { useOnClickOutside } from '../../utils/useClickOutside';
import { useRef } from 'react';

type ModalP = {
  children: React.ReactNode;
  isOpen: boolean;
  setIsOpen: (arg: boolean) => any;
};

const customStyles = {
  overlay: {
    background: 'rgba(20, 20, 20, 0.80)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
};

export default function Modal({ children, isOpen, setIsOpen }: ModalP) {
  const closeModal = () => {
    setIsOpen(false);
  };
  const selectRef = useRef(null);
  useOnClickOutside(selectRef, closeModal);

  return (
    <>
      {isOpen &&
        createPortal(
          <div className="bg-[rgba(20,20,20,0.80)] fixed top-0 left-0 right-0 bottom-0 z-50 flex items-center justify-center">
            <div
              ref={selectRef}
              style={{ backdropFilter: 'blur(6px)' }}
              className="bg-[rgba(30,30,30,0.72)] border border-white p-[30px] sm:pt-[40px] sm:pr-[68px] sm:pb-[80px] sm:pl-[68px] mx-4 max-w-[790px] w-full"
            >
              <button className="w-fit ml-auto flex  sm:mr-[-36px]" onClick={closeModal}>
                <CloseIcon />
              </button>
              {children}
            </div>
          </div>,
          document.body
        )}
    </>
  );
}
