import { useState } from 'react';
import { MainContext } from 'context/MainContext';
import { RoutesApp } from 'routs';
import { Container, Header, MainBg } from 'components';
import { useQueryClient } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';

function App() {
  const token = localStorage.getItem('mainToken');
  const [login, setLogin] = useState(!!token);
  const [isOpenLogin, setIsOpenLogin] = useState(!login);
  const [isStart, setIsStart] = useState(false);
  const [isChat, setIsChat] = useState(false);
  const [isPublicMode, setIsPublicMode] = useState(false);
  const [isPublicChat, setIsPublicChat] = useState(false);

  const [threadId, setThreadId] = useState('');

  const queryClient = useQueryClient();

  const logout = () => {
    setLogin(false);
    setIsOpenLogin(true);
    setIsStart(false);
    localStorage.removeItem('mainToken');
    setTimeout(() => {
      localStorage.removeItem('REACT_QUERY_OFFLINE_CACHE');
      queryClient?.clear();
    }, 100);
  };

  const loginF = (token: string) => {
    localStorage.setItem('mainToken', token);
    setLogin(true);
    setIsOpenLogin(false);
  };

  return (
    <MainContext.Provider
      value={{
        login,
        setLogin,
        setIsOpenLogin,
        isPublicMode,
        setIsPublicMode,
        isOpenLogin,
        isStart,
        setIsStart,
        logout,
        loginF,
        setIsChat,
        isChat,
        setThreadId,
        threadId,
        isPublicChat,
        setIsPublicChat,
      }}
    >
      <MainBg>
        <Container>
          <Header />
          <RoutesApp />
        </Container>
      </MainBg>
    </MainContext.Provider>
  );
}

export default App;
