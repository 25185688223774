import { useMutation, useQuery } from '@tanstack/react-query';
import { get, post } from 'utils/api';

const url = process.env.REACT_APP_BASE_URL;
const KEY = 'Message';

export function UseMessage(isStart: boolean, idUser: number | false, isPublicMode: boolean, threadId: string) {
  return useQuery({
    queryKey: [KEY, isStart, idUser, isPublicMode, threadId],
    queryFn: () => getMessages(isStart, idUser, isPublicMode, threadId),
  });
}

async function getMessages(isStart: boolean, idUser: number | false, isPublicMode: boolean , threadId: string) {
  if (!isStart || (!idUser && !isPublicMode)) return null;
  const urlPublic = isPublicMode ? 'AssistantAnonymous' : 'Assistant';
  const getParams = threadId ? `&threadId=${threadId}` : '';
  const  data  = await get({ path: `${url}/${urlPublic}/GetMessages?order=desc${getParams}` });
  return data;
}

export function UseMessageSend(message: string, assistantId: string, threadId: string) {
  return useMutation({
    mutationFn: () => sendMessages(message, assistantId, threadId),
  });
}

async function sendMessages(message: string, assistantId: string, threadId: string) {
  const urlPublic = assistantId ? 'AssistantAnonymous' : 'Assistant';
  const get = assistantId ? `?assistantId=${assistantId}` : '';
  const getParams = threadId ? `&threadId=${threadId}` : '';
  const data = await post({
    path: `${url}/${urlPublic}/AddMessage${get}${getParams}`,
    payload: { content: message, assistantId: assistantId },
  });
  return data;
}

export function UseRetrieveRun(id: string, threadId?: string) {
  return useMutation({
    mutationFn: () => getRetrieveRun(id,threadId),
  });
}

async function getRetrieveRun(id: string, threadId?: string) {
  const urlPublic = threadId ? 'AssistantAnonymous' : 'Assistant';
  const getParams = threadId ? `?threadId=${threadId}` : '';
  const data = await get({ path: `${url}/${urlPublic}/RetrieveRun/${id}${getParams}` });
  return data;
}
