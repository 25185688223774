import { MainContext } from 'context/MainContext';
import { useContext } from 'react';

type ContainerP = {
  children: React.ReactNode;
};

export default function Container({ children }: ContainerP) {
  const { isPublicChat } = useContext(MainContext);
  return (
    <div className={`${!isPublicChat && 'px-[16px] sm:px-[20px]'} max-w-[1440px]  mx-auto w-full"`}>{children}</div>
  );
}
