import { QueryObserverResult, RefetchOptions } from '@tanstack/react-query';
import { useEnableDisableUser } from 'containers/AdminPanel/data/useEnableDisableUser';
import { useEffect, useState } from 'react';
import { ThreeDots } from 'react-loader-spinner';
import Switch from 'react-switch';
import useGetUser from 'utils/useGetUser';

type SwitcherT = {
  value: boolean;
  id: number;
  refetch: (options?: RefetchOptions | undefined) => Promise<QueryObserverResult<any, Error>>;
};

export default function SwitcherEnableUser({ value, id, refetch }: SwitcherT) {
  const [isChecked, setIsChecked] = useState(value);
  const { isPending, mutate, isSuccess } = useEnableDisableUser(id, value);
  const user = useGetUser();
  const idUser = Number(user?.Id);

  const handleChange = () => {
    setIsChecked(!isChecked);
    mutate();
  };

  useEffect(() => {
    isSuccess && refetch();
  }, [isSuccess]);

  return (
    <>
      {idUser !== id && (
        <div className="flex items-center justify-center  w-full">
          {isPending && (
            <ThreeDots
              wrapperClass="absolute z-10 w-full top-0 left-0 right-0 bottom-0 flex items-center justify-center bg-[rgba(0,0,0,0.8)]"
              height="20"
              color="#7c4296"
              width="20"
            />
          )}
          <Switch className="m-auto" onChange={handleChange} checked={isChecked} />
        </div>
      )}
    </>
  );
}
