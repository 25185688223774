import { useMutation } from '@tanstack/react-query';
import { post } from 'utils/api';

const url = process.env.REACT_APP_BASE_URL;

export function useEnableDisableUser(id: number, disable: boolean) {
  return useMutation({
    mutationFn: () => enableDisableUser(id, disable),
  });
}

async function enableDisableUser(id: number, disable: boolean) {
  const action = disable ? 'Enable' : 'Disable';
  const { users } = await post({ path: `${url}/Admin/User/${id}/${action}`, payload: { id: id } });
  return users;
}
