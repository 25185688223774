import React, { FunctionComponent, useContext, useEffect, useRef, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import MessageItem from './MessageItem';
import { LineWave } from 'react-loader-spinner';
import { MainContext } from 'context/MainContext';
import { useParams } from 'react-router-dom';

export type MessageItemType = {
  content: {
    type: 'text';
    text: {
      value: string;
      annotations: [];
    };
  }[];
  id: string;
  role: 'assistant' | 'user';
};

interface TimelineProps {
  items: MessageItemType[];
  fetch: () => void;
  innerRef: React.RefObject<HTMLDivElement>;
  disabled: boolean;
}

const Timeline: FunctionComponent<TimelineProps> = ({ items, innerRef, disabled }) => {
  const [hasScroll, setHasScroll] = useState<boolean>(false);
  const { setIsChat, isPublicChat } = useContext(MainContext);
  const { typeDevice } = useParams();

  useEffect(() => {
    if (items.length) {
      setIsChat(true);
    } else {
      setIsChat(false);
    }
    return () => {
      setIsChat(false);
    };
  }, [items]);

  // useEffect(() => {
  //   setTimeout(() => {
  //     const element = innerRef.current;
  //     element && setHasScroll(element?.scrollHeight > element?.clientHeight);
  //   }, 1000);
  // }, [items]);

  return (
    <div
      id="scrollableDiv"
      className={`${isPublicChat ? 'h-[calc(100vh-86px)] no-scrollbar' : 'h-[calc(100vh-89.6px-83px-30px)]'} mb-[20px] pt-[10px] overflow-auto flex flex-col-reverse [&>div]:mb-auto`}
      ref={innerRef}
    >
      {/* <Scrollbars style={{ width: '100%', height: 'calc(100vh - 89.6px - 70px - 83px - 40px)', marginBottom: 20 }}> */}
      <InfiniteScroll
        dataLength={items.length}
        next={() => {}}
        inverse={true}
        hasMore={true && !disabled}
        loader={
          <div className="flex items-center justify-center ">
            <LineWave color="#7c4296" />
          </div>
        }
        scrollableTarget="scrollableDiv"
        className={`flex-col-reverse flex !overflow-hidden ${isPublicChat && 'gap-[8px]'}`}
      >
        {items.map(
          ({ id, content, role }, i) =>
            (isPublicChat ? i !== items.length - 1 : true) && (
              <MessageItem
                index={i}
                key={id}
                text={content.map((item) => item.text.value).toString()}
                to={role === 'user'}
              />
            )
        )}
      </InfiniteScroll>
      {/* </Scrollbars> */}
    </div>
  );
};

export default Timeline;
