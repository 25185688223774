export default function CopyIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="13" viewBox="0 0 15 16" fill="none">
      <path
        fill="#ffffff8c"
        className="group-focus:fill-white"
        d="M5.833 13.023C4.271 13.023 3 11.76 3 10.21V3.429H1.833A1.828 1.828 0 0 0 0 5.249v8.932C0 15.184.822 16 1.833 16h8.334A1.828 1.828 0 0 0 12 14.18v-1.158H5.833z"
      />
      <path
        fill="#ffffff8c"
        className="group-focus:fill-white"
        d="M14.857 1.92c0-1.06-.835-1.92-1.866-1.92H5.866C4.836 0 4 .86 4 1.92v8.73c0 1.062.835 1.922 1.866 1.922h7.125c1.03 0 1.866-.86 1.866-1.921V1.92z"
      />
    </svg>
  );
}
