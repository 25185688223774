import { QueryObserverResult, RefetchOptions } from '@tanstack/react-query';
import { ArrowIcon } from 'assets';
import { Button, Input, Loader } from 'components';
import { useAddNewUser } from 'containers/AdminPanel/data/useAddNewUser';
import { Form, Formik } from 'formik';
import { useContext, useEffect, useState } from 'react';
import * as Yup from 'yup';
import Switch from 'react-switch';

const validationSchema = Yup.object().shape({
  userName: Yup.string().required('Required'),
  firstName: Yup.string().required('Required'),
  lastName: Yup.string().required('Required'),
  password: Yup.string()
    .min(6, 'Your password must be at least 6 characters.')
    .max(50, 'Too Long!')
    .required('Required'),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('password'), undefined], 'Passwords must match')
    .min(6, 'Too Short!')
    .required('Password is required'),
  role: Yup.string(),
});

const initialValues = {
  userName: '',
  password: '',
  firstName: '',
  lastName: '',
  confirmPassword: '',
  role: 'User',
};

type LoginP = {
  setIsOpen: (arg: boolean) => any;
  refetch: (options?: RefetchOptions | undefined) => Promise<QueryObserverResult<any, Error>>;
};
export default function AddNewUser({ setIsOpen, refetch }: LoginP) {
  const [isValues, setValues] = useState(initialValues);
  const { isPending, mutate, isSuccess, error } = useAddNewUser(isValues);

  useEffect(() => {
    if (isSuccess) {
      setIsOpen(false);
      refetch();
    }
  }, [isSuccess]);

  return (
    <>
      <h2 className="text-[30px] sm:text-[64px] font-[500] text-white uppercase tracking-[-1.92px] leading-1 text-center mt-[20px] mb-[20px]  sm:mt-[44px]  sm:mb-[42px]">
        Add new User
      </h2>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={async (values) => {
          setValues(values);
          mutate();
        }}
      >
        {({ values, setFieldValue }) => (
          <Form>
            <Input label="login" name="userName" placeholder="Enter Login" errorRes={error} />
            <Input label="first Name" name="firstName" placeholder="Enter First Name" />
            <Input label="last Name" name="lastName" placeholder="Enter Last Name" />
            <Input label="password" type="password" name="password" placeholder="***********" />
            <Input label="confirm Password" type="password" name="confirmPassword" placeholder="***********" />

            <div className="flex flex-col gap-[6px] mb-[16px] relative">
              <span className="uppercase text-[12px] tracking-[-0.12px] text-white">Role user</span>
              <div className="flex text-white items-center gap-[10px]">
                <span>User</span>
                <Switch
                  checked={values.role !== 'User'}
                  onChange={() => setFieldValue('role', values.role === 'User' ? 'Admin' : 'User')}
                  onColor="#86d3ff"
                  onHandleColor="#262626"
                  handleDiameter={30}
                  uncheckedIcon={false}
                  checkedIcon={false}
                  height={20}
                  width={48}
                />
                <span>Admin</span>
              </div>
            </div>
            <Button type="submit" customStyle="w-full px-[15px] py-[16px] mt-[26px] group relative" style="white">
              <span className="absolute transition duration-300 transition-all left-[15px] top-[50%] translate-y-[-50%] group-hover:left-[calc(50%-60px)]">
                <ArrowIcon opacity />
              </span>
              Add
              <span className="absolute transition duration-300 transition-all right-[15px] top-[50%] translate-y-[-50%] group-hover:right-[calc(50%-60px)]">
                <ArrowIcon opacity rotate />
              </span>
            </Button>
          </Form>
        )}
      </Formik>
      <Loader isLoading={isPending} />
    </>
  );
}
