import { useQueryClient } from '@tanstack/react-query';
import { useContext, useEffect, useRef, useState } from 'react';
import useGetUser from 'utils/useGetUser';
import { MainContext } from '../../context/MainContext';
import { Button } from '../Button';
import { UseMessage } from './data/UseMessage';
import FieldChat from './FieldChat';
import Timeline from './TimeLine';
import { useNavigate, useParams } from 'react-router-dom';

export default function Chat() {
  const { login, isStart, setIsOpenLogin, setIsStart, isPublicMode, threadId, setThreadId, isPublicChat } =
    useContext(MainContext);

  const [disabled, setDisabled] = useState(true);
  const timelineRef = useRef<any>(null);
  const [isMassages, setMassages] = useState([]);
  const user = useGetUser();
  const idUser = user?.Id ? Number(user?.Id) : false;
  const { data, isPending, refetch } = UseMessage(isStart, idUser, isPublicMode, threadId);
  const queryClient = useQueryClient();
  const { threadID } = useParams();
  const threadIDModal = localStorage.getItem('threadIDModal');

  const navigate = useNavigate();

  const handleStart = () => {
    !login && !isPublicMode ? setIsOpenLogin(true) : setIsStart(true);
  };

  useEffect(() => {
    if (!isStart) {
      setMassages([]);
      queryClient.removeQueries({ queryKey: ['Message'] });
    }
  }, [isStart]);

  useEffect(() => {
    if (threadID && isPublicMode) {
      setThreadId(threadID);
    }
  }, [threadID, isPublicMode]);

  useEffect(() => {
    if (isPublicChat) {
      threadIDModal && setThreadId(threadIDModal);
      handleStart();
    }
  }, [isPublicChat]);

  useEffect(() => {
    if (data && isPublicMode && !threadId) {
      if (isPublicChat) {
        !threadIDModal && setThreadId(data?.threadId);
      } else {
        setThreadId(data?.threadId);
      }
      !isPublicChat && navigate(`${data?.threadId}`);
      isPublicChat && !threadIDModal && localStorage.setItem('threadIDModal', data?.threadId);
    }
  }, [data]);

  useEffect(() => {
    if (data) {
      setMassages(data?.data);
      setTimeout(() => {
        timelineRef.current?.scrollTo({ top: 0, left: timelineRef.current.scrollHeight - 3000, behavior: 'smooth' });
      }, 200);
    }
  }, [data]);

  const fetch = () => {
    setTimeout(() => {
      // setItems(items.concat(makeItems(items.length, items.length + 15)));
    }, 1500);
  };

  return (
    <div className="flex flex-col h-full">
      {(login || isPublicMode) && isStart && isMassages ? (
        <Timeline innerRef={timelineRef} disabled={disabled} items={isMassages} fetch={fetch} />
      ) : (
        <div
          className={`${isPublicChat ? 'min-h-[calc(100vh-66px)]' : 'min-h-[calc(100vh-89.6px-83px-30px)]'} flex items-center justify-center`}
        >
          <Button loading={isPending} onClick={handleStart}>
            Start
          </Button>
        </div>
      )}
      <FieldChat massages={data?.data} setMassages={setMassages} isLoadingMessages={isPending} updateMessages={refetch} />
    </div>
  );
}
